

(function (w, d, s, l, i) {
    w[l] = w[l] || []; w[l].push({
        'gtm.start':
            new Date().getTime(), event: 'gtm.js'
    }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-534SVHF');

var ready = false;

var queues = [];

var times = 0;
var stop = setInterval(() => {
    if (window.dataLayer == undefined) {
        times++;
        if (times > 10) {
            console.log("timeout");
            clearInterval(stop);
        }
        return;
    } else {
        clearInterval(stop);
        ready = true;
        console.log("ready");
        for (let i = 0; i < queues.length; i++) {
            dataLayer.push(queues[i]);
        }
        queues = [];
    }

}, 1000);

const gtag = function () {
    if (!ready) {
        queues.push(arguments);
    }
    dataLayer.push(arguments);
}

window.gtag = gtag;
export { gtag };