import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/func/Home.vue'
import { userStore } from '@/stores/user'
import { useGlobalStore } from '@/stores/global'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  base: import.meta.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/func/Login.vue'),
      meta: {
        withoutAuth: true,
        hideinfo: true
      }

    },
    {
      path: '/password-setting',
      name: 'PasswordSetting',
      component: () => import('@/views/func/PasswordSetting.vue'),
      meta: {
      }
    },
    {
      path: '/faq',
      name: 'Faq',
      component: () => import('@/views/func/Faq.vue'),
      meta: {
        hideFooter: true,
        hideLang: true,
        withoutAuth: true

      }
    },
    {
      path: '/forget',
      name: 'Forget',
      component: () => import('@/views/func/Forget.vue'),
      meta: {
        withoutAuth: true,
        hideinfo: true
      }
    },
    {
      path: '/password-reset',
      alias: ['/forget/set.php'],
      name: 'ResetPassword',
      component: () => import('@/views/func/PasswordReset.vue'),
      meta: {
        withoutAuth: true,
        hideinfo: true
      }
    },
    {
      path: '/otp-signup',
      name: 'Otp Signup',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('@/views/func/OtpSignup.vue'),
    },
    {
      path: '/register',
      name: 'Register',
      component: () => import('@/views/func/Register.vue'),
      meta: {
        withoutAuth: true,
        hideinfo: true
      }
    },
    {
      path: '/subscribe',
      name: 'Subscribe',
      component: () => import('@/views/func/Subscribe.vue'),
    },
    {
      path: '/totp',
      name: 'Totp',
      component: () => import('@/views/func/Totp.vue'),
      meta: {
        withoutAuth: true,
        hideinfo: true
      }
    },

    {
      path: '/verify',
      name: 'Verify',
      component: () => import('@/views/func/Verify.vue'),
    },

    {
      path: '/topup',
      name: 'Topup',
      component: () => import('@/views/func/Topup.vue'),
    },
    {
      path: '/topup/return.php',
      name: 'TopupReturn',
      component: () => import('@/views/func/TopupReturn.vue'),
    },
    // -- 以下尚未完成
    /*
*/

    {
      path: '/:pathMatch(.*)',
      redirect: { name: 'home' }
    }, // Catch-all route for 404

  ]
})

router.beforeResolve((to, from, next) => {
  const user = userStore()
  const global = useGlobalStore()

  if (to.path === '/login') {
    global.$patch({ isLogin: false });
  } else {
    global.$patch({ isLogin: true });
  }

  if (to.meta.withoutAuth) {
    next()
  } else {

    if (!user.isLogined()) {

      let link = to.fullPath;
      link = encodeURIComponent(link);
      //    if (!localStorage.getItem('token')) {
      next({
        path: '/login',
        query: { redirect: link }
      })
    } else {
      next()
    }
  }
})

export default router
