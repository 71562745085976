<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { userStore } from '@/stores/user';
import { ref } from 'vue'
import { isDev, env } from '@/env';

const user = userStore();

const show = ref(false);
</script>
<style>
.blink_me {
  animation: blinker 1s linear infinite;
  font-weight: bold;
  font-size: large;

  ::placeholder {
    animation: blinker 1s linear infinite;
  }
}


@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
<template>
  <header v-if="!$route.meta.hideHeader">
    <RouterLink v-if="isDev" :to="{ path: '/' }">
      <figure class="logo">
        <img src="@/assets/images/Logo-simple.svg" alt="X-Legend">
      </figure>
    </RouterLink>
    <div v-else>
      <figure class="logo">
        <img src="@/assets/images/Logo-simple.svg" alt="X-Legend">
      </figure>
    </div>
    <div class="menu-control">
      <svg class="icon-btn-menu" v-show="!show" @click="show = true">
        <use xlink:href="#icon-btn-menu"></use>
      </svg>
      <svg class="icon-btn-close" v-show="show" @click="show = false">
        <use xlink:href="#icon-btn-close"></use>
      </svg>
    </div>
    <div class="menu" v-if="env != 'production'">
      <div class="blink_me" :style="{ 'color': env == 'development' ? '#2196f3' : 'red' }">
        {{ env }}</div>
    </div>
    <div class="menu" v-if="user.isLogined() && !$route.meta.hideinfo" :class="{ active: show }">

      <div class="icon-link link-logout" id="MENU-Logout">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19">
          <use xlink:href="#icon-user" />
        </svg>
        <span>{{ user.getMemberId() }}</span>
      </div>
      <span class="line" v-if="!user.fromGame">/</span>
      <RouterLink v-if="!user.fromGame" :to="{ path: '/login', query: { action: 'logout' } }"
        class="icon-link link-logout" id="MENU-Logout">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19">
          <use xlink:href="#icon-logout" />
        </svg>
        <span>{{ $t("btn.logout") }}</span>
      </RouterLink>
    </div>
  </header>
</template>
