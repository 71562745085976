import { ref } from 'vue'
import { defineStore } from 'pinia'
import { mapi } from '@xlegend/mapi2';
/*
var userGameList = [];
var allGameList = [];
*/
export const userStore = defineStore('lang', () => {
    const memberid = ref(0)
    const token = ref(1)
    const fromGame = ref(2)
    fromGame.value = false;

    let urlParams = new URLSearchParams(location.search)
    if (urlParams.has("xToken")) {
        fromGame.value = true;
    }

    function getMemberId() {
        let user = mapi.getUser();
        return user.xlUserId;
    }

    function isLogined() {
        return mapi.isLogin()
    }

    function login() {
        return mapi.login();
    }

    function logout() {
        mapi.cookie("xToken2", "", {
            domain: 'x-legend.com',
            path: '/'
        });
        mapi.cookie("xlUserId", "", {
            domain: 'x-legend.com',
            path: '/'
        });
        mapi.cookie("xUsername", "", {
            domain: 'x-legend.com',
            path: '/'
        });
        mapi.cookie("xExpire2", "", {
            domain: 'x-legend.com',
            path: '/'
        });
        return mapi.logout();
    }

    async function getUserGameList() {
        return await mapi.getUserGameList();
    }

    async function getAllGameList() {
        let ret = [];
        if (typeof sessionStorage !== 'undefined') {
            let allGameList = sessionStorage.getItem('allGameList');
            if (allGameList) {
                let tmp = JSON.parse(allGameList);
                let tn = new Date().getTime();

                if (tmp['expire'] < tn) {
                    ret = tmp['data'];
                }
            }

            if (ret.length == 0) {
                allGameList = await mapi.getGameList();
                let tmp = {
                    'expire': new Date().getTime() + 300 * 1000,
                    'data': allGameList
                }
                sessionStorage.setItem('allGameList', JSON.stringify(tmp));
            }
        } else {
            ret = await mapi.getGameList();
        }
        return ret;
    }

    async function getServerList(appId) {
        return await mapi.getServerList(appId);
    }

    async function getCharList(appId, serverId) {
        return await mapi.getCharList(appId, serverId);
    }

    return { memberid, token, fromGame, getMemberId, isLogined, login, logout, getUserGameList, getAllGameList, getServerList, getCharList }
})
