<!-- eslint-disable vue/multi-word-component-names -->
<script>
import { mapi } from "@xlegend/mapi2";
export default {
    data() {
        return {
            func: {
                setpwd: true,
                cs: true,
                otp_add: false,
                otp_cancel: false,
                verify: true,
                epaper: true,
                topup: true
            },
            end: ""
        }
    },
    methods: {

    },
    mounted: function () {
        // 紀錄 cookie
        if (!mapi.isLogin()) {
            //            mapi.login();
        }
    }
}
</script>
<style scoped>
.function {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
}

.function-item {
    display: flex;
    justify-content: space-around;
    flex: auto;
}
</style>
<template>
    <div class="main" id="page-main">
        <div class="tab">
            <ul>
                <li class="active"><a href="#">{{ $t("tab.member") }}</a></li>
                <li><a href="topup/" v-show="func.topup">{{ $t("tab.purchase") }}</a></li>
            </ul>
        </div>
        <div class="box">
            <div class="function">
                <div class="function-item function-pwd" v-show="func.setpwd">
                    <div class=" card">
                        <div class="card-top">

                            <RouterLink to="/password-setting">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="30">
                                    <use xlink:href="#icon-lock" />
                                </svg>
                            </RouterLink>
                        </div>
                        <div class="card-body">
                            {{ $t("title.password_setting") }}
                        </div>
                    </div>
                </div>

                <div class="function-item function-report" v-show="func.cs">
                    <div class="card">
                        <div class="card-top">
                            <RouterLink to="/faq" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="26">
                                    <use xlink:href="#icon-report" />
                                </svg>
                            </RouterLink>
                        </div>
                        <div class="card-body">
                            {{ $t("title.report") }}
                            <svg xmlns="http://www.w3.org/2000/svg" style="width:18px;height:18px">
                                <use xlink:href="#icon-open" />
                            </svg>
                        </div>
                    </div>
                </div>

                <div class="function-item function-otp" v-show="func.otp_add">
                    <div class="card">
                        <div class="card-top">
                            <RouterLink to="/otp-signup">
                                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="26">
                                    <use xlink:href="#icon-otp" />
                                </svg>
                            </RouterLink>
                        </div>
                        <div class="card-body">
                            {{ $t("title.otp_signup") }}
                        </div>
                    </div>
                </div>

                <div class="function-item function-otpcancel" v-show="func.otp_cancel">
                    <div class="card">
                        <div class="card-top">
                            <RouterLink to="/otp-cancel">
                                <svg xmlns="http://www.w3.org/2000/svg" width="46" height="38">
                                    <use xlink:href="#icon-otp-cancel" />
                                </svg>
                            </RouterLink>
                        </div>
                        <div class="card-body">
                            {{ $t("title.otp_cancel") }}
                        </div>
                    </div>
                </div>

                <div class="function-item function-verify" v-show="func.verify">
                    <div class="card">
                        <div class="card-top">
                            <RouterLink to="/verify">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="19">
                                    <use xlink:href="#icon-mail" />
                                </svg>
                            </RouterLink>
                        </div>
                        <div class="card-body">
                            {{ $t("title.verify") }}
                        </div>
                    </div>
                </div>

                <div class="function-item function-subscribe" v-show="func.epaper">
                    <div class="card">
                        <div class="card-top">
                            <RouterLink to="/subscribe">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26">
                                    <use xlink:href="#icon-subscribe" />
                                </svg>
                            </RouterLink>
                        </div>
                        <div class="card-body">
                            {{ $t("title.subscribe") }}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>