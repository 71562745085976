import '@/assets/css/member.css'

import { i18n } from '@xlegend/mapi2';
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import { setI18n } from './utils/locale';
import { mapi } from "@xlegend/mapi2";
import * as Sentry from '@sentry/vue';
import 'element-plus/dist/index.css';
import { loginByToken } from './api/auth';

if (typeof urlParams != 'object') {
    var urlParams = {};
    try {
        (function () {
            var e,
                a = /\+/g,  // Regex for replacing addition symbol with a space
                r = /([^&=]+)=?([^&]*)/g,
                d = function (s) { return decodeURIComponent(s.replace(a, " ")); },
                q = window.location.search.substring(1);
            while (e = r.exec(q)) {
                urlParams[d(e[1])] = d(e[2]);
            }
        })();
    } catch (err) {
        // nothing
    }
    window.urlParams = urlParams;
}

Sentry.init({
    app: null,
    dsn: "https://4801d59adde5e839f1b932ee7c89011c@sentry.x-legend.com/2",
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        //        Sentry.replayIntegration(),
    ],
    logErrors: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

mapi.setGameType(9);



i18n.getI18n({
    key: "member", oninit: function (lang) {

    },
    /*
    interpolation: {
        escapeValue: false,
        useRawValueToEscape: true,
    }
    */
}).then(async function (i2) {

    let urlParams = new URLSearchParams(location.search)
    if (urlParams.has("xToken")) {
        let tk = urlParams.get("xToken");
        if (!await loginByToken(tk)) {
            alert("Token expired, please login again.");
            location.href = "/member/";
        }
    }

    setI18n(i2);

    const app = createApp(App)
    app.use(createPinia())
    app.use(i2.getI18nVue(), { i18next: i2.getI18n() });
    app.use(router)

    Sentry.init({
        app: app,
    });
    app.mount('#app')
});
